import React, { useEffect } from "react"
import classNames from "classnames"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { HeroSection } from "./heroSection"

// TODO: video en l'état
// TODO: limiter taille image 1920px

const GridImage = ({ url, title, src, order, span, index, ...props }) => {
  const grayscaleVariants = {
    hidden: {
      filter: "grayscale(1)",
    },
    visible: {
      filter: "grayscale(0)",
      transition: {
        duration: 1,
      },
    },
  }

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 1,
  })

  const imgClasses = classNames(
    "relative row-start-1 row-end-2 overflow-hidden",
    {
      "col-start-3 col-end-13 lg:col-start-6 col-end-13":
        order === 2 && span === 7,
      "col-start-1 col-end-10 lg:col-start-1 lg:col-end-8":
        order === 1 && span === 8,
    },
    {
      "lg:-mt-14 lg:mb-10": index === 0,
      "relative z-20 mt-5 lg:-mt-14 lg:-mb-14": index === 2,
      "lg:-mb-14 lg:mt-14": index === 4,
    },
  )

  const voirPlusClasses = classNames(
    "absolute inline w-auto -rotate-180 py-2 uppercase border-r-8",
    {
      "bottom-0 left-0 border-r-visuall-print text-visuall-print": index === 0,
      "top-0 left-0 border-r-visuall-web text-visuall-web": index === 1,
      "top-0 left-0 border-r-visuall-red text-visuall-red": index === 2,
      "top-0 left-0 border-r-visuall-video text-visuall-video": index === 3,
      "top-0 left-0 border-r-visuall-digital text-visuall-digital": index === 4,
    },
  )

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [inView, controls])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={grayscaleVariants}
      className={imgClasses}
    >
      <motion.div whileHover={{ scale: 1.2 }}>
        <GatsbyImage alt={`illustration ${title}`} image={src} />
      </motion.div>
      <div
        className={voirPlusClasses}
        style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
      >
        voir +
      </div>
      <Link to={url} title={title} className="absolute inset-0" />
    </motion.div>
  )
}

const GridTitle = ({ title, order, span, category }) => {
  const titleClasses = classNames(
    "relative flex self-start font-archia text-xl row-start-2 row-end-3 -mt-5 lg:mt-0 lg:row-start-1 lg:row-end-2 self-center px-2 lg:text-6xl",
    {
      "col-start-1 col-end-10 lg:col-start-1 lg:col-end-7":
        order === 1 && span === 6,
      "col-start-3 col-end-13 lg:col-start-7 lg:col-end-12":
        order === 2 && span === 5,
      "col-start-1 col-end-10 lg:col-start-1 lg:col-end-6":
        order === 1 && span === 5,
      "visuall-print": category === "Print Design",
      "visuall-video": category === "Video Teaser",
      "visuall-web": category === "Web Strategy",
      "visuall-brand": category === "Brand Identity",
      "visuall-digital": category === "Digital Marketing",
    },
  )

  const categoryClasses = classNames(
    "break-all text-xs transform text-visuall-black -rotate-180 flex lg:self-baseline lg:mt-5 lg:text-base before:mb-2 before:w-0.5 before:h-5 before:self-center",
    {
      "before:bg-visuall-print": category === "Print Design",
      "before:bg-visuall-video": category === "Video Teaser",
      "before:bg-visuall-web": category === "Web Strategy",
      "before:bg-visuall-brand": category === "Brand Identity",
      "before:bg-visuall-digital": category === "Digital Marketing",
    },
  )

  const titleSpanClasses = classNames(
    "font-archia self-end lg:self-start break-words text-md lg:text-6xl font-light text-visuall-gris-logo pl-2 lg:pl-5",
    {
      "pt-5": order === 1,
    },
  )

  const containerTitleVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const titleVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <motion.div
      className={titleClasses}
      initial="hidden"
      whileInView="visible"
      variants={containerTitleVariants}
    >
      <motion.div
        className={categoryClasses}
        style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
        variants={titleVariants}
      >
        {category}
      </motion.div>
      <motion.span
        variants={titleVariants}
        className={titleSpanClasses}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </motion.div>
  )
}

const Grid = ({ block, index }) => {
  const itemLayout = {
    0: {
      orderImage: 2,
      spanImage: 7,
      orderTitle: 1,
      spanTitle: 6,
    },
    1: {
      orderImage: 1,
      spanImage: 8,
      orderTitle: 2,
      spanTitle: 5,
    },
    2: {
      orderImage: 2,
      spanImage: 7,
      orderTitle: 1,
      spanTitle: 5,
    },
    3: {
      orderImage: 1,
      spanImage: 8,
      orderTitle: 2,
      spanTitle: 5,
    },
    4: {
      orderImage: 2,
      spanImage: 7,
      orderTitle: 1,
      spanTitle: 5,
    },
  }
  return (
    <div className="container mx-auto grid grid-cols-12 grid-row-2 lg:grid-rows-1 mt-5 lg:mt-0 mb-10 lg:mb-0">
      <GridImage
        order={itemLayout[index].orderImage}
        span={itemLayout[index].spanImage}
        src={block.illustration.gatsbyImageData}
        index={index}
        title={`Service ${block.category.title}`}
        url={block.category.slug}
      />
      <GridTitle
        order={itemLayout[index].orderTitle}
        span={itemLayout[index].spanTitle}
        title={block.titreHtml}
        category={block.category.title}
        color={block.category.color.hex}
      />
    </div>
  )
}

const Home = ({ home }) => {
  return (
    <div>
      <HeroSection video={home.videoIntro} />
      <section className="bg-visuall-bg pb-12 lg:pb-0 lg:mb-12 lg:mb-40">
        {home.sections.map((item, i) => {
          return <Grid index={i} key={`home-${i}`} block={item} />
        })}
      </section>
    </div>
  )
}

export default Home
