import React, { useContext, useEffect, useState } from "react"
import { storeCookies } from "./cookies-reducer"
import { CookiesContext } from "./CookieContext"
export const CookieSocialConsent = () => {
  const { cookies, dispatch } = useContext(CookiesContext)
  const [prevCookies, setPrevCookies] = useState(cookies)

  useEffect(() => {
    storeCookies(cookies)
    if (JSON.stringify(prevCookies) !== JSON.stringify(cookies)) {
      setPrevCookies(cookies)
      window.location.reload()
    }
  }, [cookies])

  return (
    <>
      <div className="cookie-consent bg-visuall-black text-white p-8 flex text-center justify-center items-center w-full aspect-video">
        <div className={"max-w-2xl p-8 bg-visuall-gris-logo border-white/80"}>
          <p className={"mb-2"}>
            Pour afficher ce contenu YouTube, vous devez accepter les cookies{" "}
            <b>Réseaux Sociaux</b>.
          </p>
          <p>
            Ces cookies offrent la possibilité de partager ou réagir facilement
            sur les réseaux sociaux auxquels vous êtes connectés, ainsi que
            d'intégrer du contenu initialement publié sur ces plateformes. Ils
            autorisent également les réseaux sociaux à utiliser vos visites sur
            nos sites et applications pour des objectifs de personnalisation et
            de ciblage publicitaire.
          </p>
          <div class="mx-auto mt-4">
            <div className={"grid grid-cols-button gap-2 justify-center"}>
              <button
                className={
                  "px-4 py-1 border-2 border-white rounded-full hover:bg-white/20 transition hover:-translate-y-1 duration-500 active:translate-y-0.5"
                }
                onClick={() => dispatch({ type: "OPEN_MANAGEMENT" })}
              >
                Gérer mes choix
              </button>
              <button
                className={
                  "px-4 py-1 border-2 border-white rounded-full bg-white text-gray-600 hover:bg-white/80 hover:-translate-y-1 transition duration-500 active:translate-y-0.5"
                }
                onClick={() => dispatch({ type: "ACCEPT_COOKIES" })}
              >
                J'autorise
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
