import React, { useEffect, useRef, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { CookieSocialConsent } from "../GDPR/cookies"
import { CookiesContext } from "../GDPR/CookieContext"

const videoVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
}

const YoutubeVideo = ({ id }) => {
  const { cookies, dispatch } = React.useContext(CookiesContext)
  const videoContainer = useRef(null)
  const onScreen = useInView(videoContainer)

  return (
    <motion.div id={"videoVisuall"} variants={videoVariants}>
      <div ref={videoContainer} className={"w-full h-full"}>
        {!cookies.social && (
          <CookieSocialConsent cookies={cookies} dispatch={dispatch} />
        )}
        {onScreen && cookies.social === true && (
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${id}?autoplay=1&rel=0`}
            title={"Visuall Communication Media"}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            className="w-full aspect-video"
          />
        )}
        {!onScreen && (
          <img
            alt={"Visuall Media"}
            className={"object-cover"}
            src={`https://i.ytimg.com/vi/${id}/hqdefault.jpg`}
          />
        )}
      </div>
    </motion.div>
  )
}

const ButtonPlayMaskSvg = ({ id }) => {
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(true)
  const videoV = useRef(null)

  const toggleMuting = () => {
    setMuted(!muted)
    if (muted) {
      videoV.current.muted = false
    } else {
      videoV.current.muted = true
    }
  }

  const togglePlaying = () => {
    setPlaying(!playing)
    if (playing) {
      videoV.current.pause()
    } else {
      videoV.current.play()
    }
  }

  useEffect(() => {
    //!playing && document.getElementById("videoVisuall").pause()
  }, [playing, setPlaying])

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.4,
      },
    },
  }

  return (
    <>
      <AnimatePresence>
        {isPlaying && (
          <motion.div
            className="fixed inset-0 lg:p-12 z-50 bg-white flex justify-center items-center overflow-hidden"
            initial="hidden"
            animate={isPlaying ? "visible" : "hidden"}
            exit="hidden"
            variants={containerVariants}
          >
            <div className="absolute top-1/2 lg:top-1/4 left-1/4 w-full h-full bg-visuall-bleu-logo" />
            <div className="relative w-full h-full px-2 lg:p-8">
              <button
                label="Fermer la vidéo"
                className="absolute -top-8 lg:top-0 right-1 text-visuall-gris-logo text-4xl"
                onClick={() => setIsPlaying(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="relative h-full w-full">
                <YoutubeVideo id={id} player={player} setPlayer={setPlayer} />
              </div>
              {1 === 0 && (
                <div className="flex space-x-5 justify-end py-4">
                  <button
                    className="text-white"
                    onClick={() => togglePlaying()}
                  >
                    {playing && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                    {!playing && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                  </button>
                  <button className="text-white" onClick={() => toggleMuting()}>
                    {!muted && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                        />
                      </svg>
                    )}
                    {muted && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                          clipRule="evenodd"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <svg
        id="header"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Calque 1"
        viewBox="0 0 1280 720"
        onClick={() => setIsPlaying(true)}
        className="cursor-pointer svg-video-visuall"
      >
        <g className="header-backdrop" mask="url(#video-mask)">
          <rect id="cover" x="-500%" y="-500%" width="1000%" height="1000%" />
        </g>
        <g id="mask-video" className="transparent-svg">
          <path
            id="heading"
            d="M257.9 565.73 532.22 360 257.9 154.27ZM362.83 3.55C166.28 3.55 6.38 163.45 6.38 360s159.9 356.45 356.45 356.45S719.29 556.55 719.29 360 559.38 3.55 362.83 3.55Zm0 644.53C204 648.08 74.75 518.85 74.75 360S204 71.92 362.83 71.92 650.91 201.15 650.91 360 521.68 648.08 362.83 648.08Z"
          />
        </g>
        <mask id="video-mask">
          <use xlinkHref="#cover" style={{ fill: "white" }} /> 6
          <use xlinkHref="#heading" />
        </mask>
      </svg>
    </>
  )
}

export default ButtonPlayMaskSvg
