import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../components/Home"
import ContactFormSection from "../components/ContactFormSection"

const IndexPage = ({ data }) => {
  const HOME = data.datoCmsHomePage
  return (
    <Layout>
      <Home home={HOME} />
      <ContactFormSection />
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsHomePage {
      titre
      description
      seoMetaTags {
        tags
      }
      videoIntro {
        provider
        providerUid
        height
        thumbnailUrl
        title
        url
        width
      }
      sections {
        illustration {
          gatsbyImageData
        }
        category {
          color {
            hex
            rgb
          }
          slug
          title
        }
        titreHtml
      }
    }
  }
`

export default IndexPage

export const Head = ({ data, location }) => {
  const HOME = data.datoCmsHomePage
  return (
    <Seo
      title={HOME.titre}
      description={HOME.description}
      lang={HOME.locale}
      canonical={location.pathname}
      metaTags={HOME.seoMetaTags}
    />
  )
}
