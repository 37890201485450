import React from "react"
import { motion } from "framer-motion"

const SimplifySvg = props => {
  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.7,
        staggerChildren: 0.15,
      },
    },
  }

  const groupVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
      },
    },
  }

  const letterVariants = {
    hidden: {
      opacity: 0,
      //x: 10,
    },
    visible: {
      opacity: 1,
      //x: 0,
    },
  }

  return (
    <motion.svg
      id="ada96d28-8008-44e5-9b2d-13c9ca42b0de"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000.67 191.86"
      initial="hidden"
      animate="visible"
      variants={svgVariants}
    >
      <path
        d="M169.35,469l7.72-1.93c.64,7.5,5.9,11.36,14.36,11.36s13-4.07,13-9.76c0-15.63-33.33-3.74-33.33-25.82,0-9.43,8.47-15.53,20.14-15.53,9.65,0,19,5.57,20.27,15.64l-7.41,2.15c-.74-6.6-6.1-10.5-13.38-10.5-7,0-11.55,3.42-11.55,8.24,0,14.15,33.44,2.36,33.44,25.41,0,10.48-8.67,17.56-21.65,17.56C178.47,485.86,170.64,479,169.35,469Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M235.48,435.93H225v-7.1h19v55.72h-8.46Zm-1.93-23.48a6.17,6.17,0,1,1,6.12,6.22h0a6,6,0,0,1-6.1-5.83Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M264.07,428.83h8.48v8h.64a17.53,17.53,0,0,1,16.27-9.43c8.36,0,14.48,4.07,17.58,10.17h.76c3.74-7.17,9.89-10.17,17.67-10.17,12.43,0,20.48,8.79,20.48,21v36.1h-8.48v-33.4c0-10.5-5.24-15.86-13.5-15.86-7.82,0-14.67,5.57-14.67,16v33.22h-8.46V449.53c0-8.88-6-14.24-13.82-14.24s-14.46,6.21-14.46,15.65v33.53h-8.49Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M363.11,428.83h8.13v8.78h.64a21.6,21.6,0,0,1,18.75-10.17c15.86,0,27.22,12.1,27.22,29.15S407,485.94,391.27,485.94c-11.67,0-17.25-7-19.08-10.39h-.64v33.57h-8.46Zm46.05,27.68c0-13.08-8.36-21.44-19.08-21.44-11.54,0-18.86,9.75-18.86,21.44,0,13.72,8.58,21.63,19.08,21.63C401.26,478.14,409.16,469.25,409.16,456.51Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M425.91,477.18h11.28v-65.8H425.94v-7.29h19.79v73.09H457v7.29H425.91Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M474.35,435.93h-10.5v-7.1h19v55.72h-8.46Zm-1.93-23.48a6.17,6.17,0,1,1,6.12,6.22h0a6,6,0,0,1-6.1-5.83C472.41,412.71,472.42,412.58,472.42,412.45Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M507,435.93H496.54v-7.1H507V420c0-11.25,6.76-15.86,16.94-15.86h7.4v7.4h-7.5c-5.9,0-8.36,2.57-8.36,8.57v8.68h16.29V436H515.5v48.54H507Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M542,501.37h6.22c5.14,0,7.6-2.14,9.33-6.6l4-10.38h-2.28l-20.44-55.56h9.43l16.29,47.58h.64l17.25-47.58h9.55L564.78,498.7c-2.78,7.29-7.91,10.5-16.93,10.5H542Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M629.41,501.37h6.22c5.14,0,7.6-2.14,9.31-6.6l4-10.38h-2.26L626.2,428.83h9.43l16.29,47.58h.64l17.25-47.58h9.54L652.23,498.7c-2.78,7.29-7.93,10.5-16.93,10.5h-5.89Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M685.78,456.61a28.48,28.48,0,0,1,27.68-29.25h1.47c16.82,0,29,12.53,29,29.25a28.47,28.47,0,0,1-27.68,29.24c-.49,0-1,0-1.47,0C698.1,485.86,685.78,473.54,685.78,456.61Zm49.47,0c0-12.32-8.48-21.54-20.48-21.54s-20.38,9.32-20.38,21.54c0,12.54,8.48,21.53,20.36,21.53s20.55-9.1,20.55-21.53Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M756.83,463.78V428.83h8.46v34.62a14.09,14.09,0,0,0,13.5,14.66,8.23,8.23,0,0,0,.86,0,15.45,15.45,0,0,0,15.65-15.27c0-.23,0-.46,0-.69V428.83h8.46v55.72h-8.46v-8.14h-.64c-2.36,5-8.36,9.53-17,9.53C764.66,485.86,756.83,476.11,756.83,463.78Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <path
        d="M820.91,428.83h8.36v9.33h.65a16.47,16.47,0,0,1,15.21-10.72h3.65v8.46h-5c-10.19,0-14.36,8.24-14.36,18.86v29.79h-8.48Z"
        transform="translate(0.67 -404.09)"
        fill="#595766"
      />
      <motion.g variants={groupVariants}>
        <motion.path
          variants={letterVariants}
          d="M162.82,566.31c0-16.48,11.54-29.67,29.37-29.67,13.71,0,24.73,7.28,28.39,21.43l-14.46,3.64a13.28,13.28,0,0,0-13.51-10.17c-8.57,0-13.93,6.43-13.93,14.84s5.46,14.67,13.93,14.67A13.18,13.18,0,0,0,206,571.16l14.58,3.55c-3.53,14-14.68,21.21-28.39,21.21C174.4,595.89,162.82,582.6,162.82,566.31Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M227,566.31c0-16.83,12.54-29.67,30.12-29.67s30,13,30,29.67-12.43,29.58-30,29.58S227,583,227,566.31Zm44.27,0c0-8.47-5.79-14.84-14.15-14.84s-14.26,6.32-14.26,14.84S248.77,581,257.13,581,271.28,574.67,271.28,566.31Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M296.24,538h15.64v5.15h1.19a15,15,0,0,1,12.86-6.6,16.12,16.12,0,0,1,14.84,8.24H342a18,18,0,0,1,15.63-8.24,19.42,19.42,0,0,1,20,18.78c0,.34,0,.67,0,1v38.15H362V561.7c0-6.6-3.64-9.9-8.46-9.9s-8.79,3.65-8.79,9.9v32.79H329.2V560.31a8.25,8.25,0,0,0-7.9-8.58c-.26,0-.52,0-.78,0-4.83,0-8.69,3.86-8.69,9.74v33H296.19Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M388.4,538h15.65v5.15h1.19a15,15,0,0,1,12.86-6.6,16.1,16.1,0,0,1,14.83,8.24h1.19a18,18,0,0,1,15.63-8.24,19.42,19.42,0,0,1,20,18.78c0,.34,0,.67,0,1v38.15H454.15V561.7c0-6.6-3.64-9.9-8.45-9.9s-8.79,3.65-8.79,9.9v32.79H421.36V560.31a8.25,8.25,0,0,0-7.9-8.58c-.25,0-.51,0-.77,0-4.83,0-8.69,3.86-8.69,9.74v33H388.35Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M479.49,576V538h15.65v32.89c0,5.69,3.86,9.89,9.32,9.89a9.48,9.48,0,0,0,9.65-9.3c0-.2,0-.39,0-.59V538h15.65v56.47H514.1v-5.14H513a16.2,16.2,0,0,1-13.72,6.59,19.29,19.29,0,0,1-19.82-18.74C479.48,576.79,479.48,576.37,479.49,576Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M540.46,538h15.65v5.15h1.07a16.23,16.23,0,0,1,13.72-6.6,19.29,19.29,0,0,1,19.79,18.78c0,.38,0,.77,0,1.15v37.92H575v-33c0-5.69-3.86-9.89-9.33-9.89a9.5,9.5,0,0,0-9.66,9.34c0,.18,0,.37,0,.55v32.79H540.42Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M608.66,551.84h-9.32V538H624.2v56.47H608.66Zm-1.29-30.44a9.28,9.28,0,1,1,9.45,9.12h-.12a9,9,0,0,1-9.36-8.63v-.49Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M634.46,566.31c0-16.48,11.54-29.67,29.36-29.67,13.72,0,24.73,7.28,28.39,21.43l-14.46,3.64a13.27,13.27,0,0,0-13.5-10.17c-8.57,0-13.93,6.43-13.93,14.84s5.46,14.67,13.93,14.67a13.2,13.2,0,0,0,13.39-9.89l14.57,3.55c-3.52,14-14.67,21.21-28.39,21.21C646,595.89,634.46,582.6,634.46,566.31Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M698.64,566.2c0-17,11.55-29.58,25.94-29.58a19.8,19.8,0,0,1,15.65,6.59h1.18V538H757v41a2.31,2.31,0,0,0,2.25,2.37h2.58v13.07H747.73a6,6,0,0,1-6.22-5.85v-.15h-1.17c-3,4.29-7.5,7.4-15.65,7.4C710.22,595.89,698.64,583.13,698.64,566.2Zm43,.11c0-9.33-6.2-14.57-13.6-14.57s-13.5,5.67-13.5,14.57,6.11,14.58,13.6,14.58C736.25,580.89,741.61,574.24,741.61,566.31Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M777.64,577V551.84H768.3V538h10.39V522.59h14.62V538h15v13.82h-15v25.07c0,2.48,1,3.55,3.55,3.55h10.82v14H795.36C784,594.49,777.64,589.35,777.64,577Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M825.1,551.84h-9.31V538h24.84v56.47H825.1Zm-1.28-30.44a9.27,9.27,0,1,1,9.41,9.12h-.1a9,9,0,0,1-9.31-8.68C823.82,521.69,823.81,521.55,823.82,521.4Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M850.92,566.31c0-16.83,12.55-29.67,30.12-29.67s30,13,30,29.67-12.43,29.58-30,29.58S850.92,583,850.92,566.31Zm44.27,0c0-8.47-5.79-14.84-14.15-14.84s-14.26,6.32-14.26,14.84S872.68,581,881,581s14.15-6.32,14.15-14.68Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M920.15,538H935.8v5.15h1.07a16.21,16.21,0,0,1,13.72-6.6,19.29,19.29,0,0,1,19.79,18.78c0,.38,0,.77,0,1.15v37.92H954.73v-33c0-5.69-3.86-9.89-9.34-9.89a9.49,9.49,0,0,0-9.65,9.34c0,.18,0,.37,0,.55v32.79H920.1Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
        <motion.path
          variants={letterVariants}
          d="M981.67,586.89a8.92,8.92,0,0,1,8.86-9H991a9,9,0,1,1-9.33,9.47C981.67,587.2,981.66,587.05,981.67,586.89Z"
          transform="translate(0.67 -404.09)"
          fill="#00aecb"
        />
      </motion.g>
      <g id="b1007d5a-afc4-496e-94f5-4a23aea6da08" data-name="Ligne 21">
        <rect y="46.64" width="141.77" height="19.74" fill="#00aecb" />
      </g>
    </motion.svg>
  )
}

SimplifySvg.propTypes = {}

export default SimplifySvg
